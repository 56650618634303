@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.PostProjectBanner {
    background: @quaternary-color;
    bottom: 0;
    color: @text-color-light;
    display: none;
    padding: @spacing-mid 0;
    position: fixed;
    width: 100%;
    z-index: @z-index-popover;

    @media (min-width: @breakpoint-desktop-small) {
        display: block;
        padding: @spacing-large 0;
    }

    &-heading {
        .type(heading3);

        @media (min-width: @breakpoint-tablet) {
            .type(heading2);
            margin-bottom: @spacing-xsmall;
        }
    }

    &-byLine {
        .type(landing);
        display: none;
        margin-bottom: 0;

        @media (min-width: @breakpoint-tablet) {
            display: block;
        }
    }

    &-closeButton {
        position: absolute;
        right: @spacing-mid;
        top: @spacing-mid;
    }
}
