@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.status-label {
    display: inline-flex;
    align-items: center;
    padding: 2px 7px;
    border: 1px solid @border-color-light;
    border-radius: 3px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: @quaternary-color-xlight;

    &::before,
    &::after {
        text-transform: none;
    }

    &--light {
        color: @text-color-light;
    }

    &.is-open {
        border-color: @primary-color-xlight;
        color: @primary-color-xlight;

        &.has-fill {
            border: @quinary-color-light;
            color: @septenary-color-xxxlight;
            background: @quinary-color-light;
        }
    }

    &.is-closed {
        border-color: @septenary-color;
        color: @septenary-color;
    }

    &.is-pending {
        border-color: @featured-color;
        color: @featured-color;
    }

    &.is-awarded {
        border-color: @featured-color;
        color: @featured-color;
    }

    &.is-featured {
        border-color: @featured-color;
        color: @featured-color;
    }

    &.is-guaranteed {
        border-color: @guaranteed-color;
        color: @guaranteed-color;
    }

    &.is-recruiter {
        border-color: @recruiter-color;
        color: @recruiter-color;
    }

    &.is-private {
        border-color: @private-color;
        color: @private-color;
    }

    &.is-nda {
        border-color: @nda-color;
        color: @nda-color;
    }

    &.is-sealed {
        border-color: @sealed-color;
        color: @sealed-color;
    }

    &.is-urgent {
        border-color: @urgent-color;
        color: @urgent-color;
    }

    &.is-fulltime {
        border-color: @fulltime-color;
        color: @fulltime-color;
    }

    &.is-progress {
        border-color: @quinary-color;
        color: @quinary-color;
    }

    &.is-recommended {
        border-color: @quinary-color-light;
        color: @quinary-color-light;
    }

    &.is-new {
        border-color: @septenary-color-xlight;
        color: @septenary-color-xlight;
    }

    &.is-top-contest {
        border-color: @quaternary-color-xdark;
        color: @quaternary-color-xdark;
    }

    &.is-sponsored {
        border-color: @tertiary-color-xlight;
        color: @tertiary-color-xlight;
    }

    &.is-success {
        border-color: @quinary-color-light;
        color: @quinary-color-light;
    }

    &.is-danger {
        border-color: @secondary-color-light;
        color: @secondary-color-light;
    }

    &.is-active {
        border-color: @primary-color;
        color: @primary-color;
    }

    &.is-large {
        .type(detail);
        min-height: 20px;
        padding: 0px 8px;
        border-radius: 10px;
        line-height: 20px;
    }

    &.is-xlarge {
        .type(detail);
        height: 32px;
        padding: 8px 14px;
        border-radius: 16px;
    }
}
