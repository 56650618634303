@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import "flux/modules/status-label.less";
@import "flux/modules/promotion-tags.less";

.JobSearchCard {
    @this: JobSearchCard;

    &-ctas {
        display: none;

        .@{this}-item:hover & {
            @media (min-width: @breakpoint-desktop-small) {
                display: block;
                margin-top: @spacing-xxsmall + @spacing-xsmall;
            }
        }

        &-btn {
            &:not(:last-child) {
                margin-bottom: @spacing-xxsmall;
            }
        }

        &--noTop {
            .@{this}-item:hover & {
                margin-top: 0;
            }
        }
    }

    &-item {
        padding: 0 @spacing-mid;

        @media (max-width: @breakpoint-mobile) {
            padding: 0 18px;
        }

        &--highlight {
            background-color: @tertiary-color-xxxlight;
        }

        &:hover {
            background-color: @quaternary-color-xxxxxlight;
            cursor: pointer;
        }

        &-inner {
            border-bottom: 1px solid @border-color-light;
            padding: @spacing-mid 0;

            @media (min-width: @breakpoint-desktop-small) {
                display: table;
                table-layout: fixed;
                width: 100%;
            }
        }
    }

    &-primary {
        .text-wrap();
        display: table-cell;
        padding-right: @spacing-mid;

        &-heading {
            margin-bottom: @spacing-xsmall;

            &-Icon {
                margin-right: @spacing-xxsmall;

                .flicon-trophy,
                .Icon-image {
                    stroke: @tertiary-color-xlight;
                }
            }

            &-link {
                .type(landing);
                color: @quaternary-color-xxxdark;
                font-weight: bold;
                margin-right: @spacing-xxsmall;
            }

            &-daystatus {
                display: block;
                line-height: @spacing-mid;
                padding: @spacing-xxsmall 0;

            }

            &-status {
                .type(detail);
                color: @quinary-color;
                display: inline-block;
                font-weight: bold;
                margin-left: 0;
            }

            &-days {
                display: inline-block;
                margin-right: @spacing-xsmall + @spacing-xxsmall;
            }
        }

        &-tagsLink {
            margin-bottom: @spacing-xsmall;
            margin-right: @spacing-xsmall;
        }

        &-description {
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            margin-bottom: @spacing-xsmall;
            max-height: 96px;
            overflow: hidden;
            word-break: break-word;
            word-wrap: break-word;

            &-link {
                font-weight: bold;
            }
        }

        &-promotion {
            margin-bottom: -@spacing-xsmall;
            margin-top: @spacing-small;

            &-tag {
                margin-bottom: @spacing-xsmall;
            }
        }

        &-hidden {
            display: block;
            margin-top: @spacing-xsmall;

            @media (min-width: @breakpoint-desktop-small) {
                display: none;
            }
        }

        &-price {
            align-items: center;
            display: flex;
            font-weight: bold;
        }

        /** This is the hack to make sure it is same as design */

        &-iconSmall,
        &-iconSmall {
            .Icon-image {
                height: 12px;
                width: 12px;
            }
        }

        &-status {
            .type(detail);
            color: @quinary-color;
            font-weight: bold;
            margin-left: @spacing-xsmall;
        }

        &-avgBid {
            .type(detail);
            font-weight: normal;
            margin-left: @spacing-xsmall;
        }
    }

    &-secondary {
        display: none;
        vertical-align: top;

        @media (min-width: @breakpoint-desktop-small) {
            display: table-cell;
            padding: 0 28px;
            width: 160px;
        }

        &-price {
            .type(landing);
            font-weight: bold;
            margin-bottom: @spacing-xsmall;
        }

        &-avgBid {
            .type(detail);
            font-weight: normal;
        }

        &-entry {
            .type(ui);
        }

        &-status {
            .type(detail);
            color: @quinary-color;
            font-weight: bold;
            margin-top: @spacing-small;
        }

        /** This is the hack to make sure it is same as design */

        &-iconSmall,
        &-iconSmall {
            .Icon-image {
                height: 12px;
                width: 12px;
            }
        }
    }
}

.JobSearchCard-primary-heading-status .Icon-image {
    height: auto;
    width: 20px;
}
