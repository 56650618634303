@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import (reference) "libs/bootstrap.3.2.grid.less";
@import "flux/modules/JobSearchCard.less";
@import "flux/modules/PostProjectBanner.less";
@import "flux/modules/InputCombo.less";

/* Animations */
/* animation for fading */
@keyframes fadeInLoop {
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
        transform: translateY(5px);
    }
}

/* animation for unicorn */
@keyframes bounceinfinity {
    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }
}

/* flapping wings animation */
@keyframes flapWingLeft {
    0%,
    50%,
    100% {
        transform: rotate(0deg);
    }

    25%,
    75% {
        transform: rotate(-35deg);
    }
}

@keyframes flapWingRight {
    0%,
    50%,
    100% {
        transform: rotate(0deg);
    }

    25%,
    75% {
        transform: rotate(35deg);
    }
}

.FourOhFour {
    background: @primary-color-xxxdark;
    overflow: hidden;
    position: relative;
    width: 100%;

    &::before {
        .absolute-cover();
        background: url("/static/img/universe.jpg") no-repeat center / cover;
        content: "";
    }

    &-delight {
        .make-md-column(6);
        .make-md-column-push(6);
        min-height: 240px;
        padding: 50px 0;
        text-align: center;
        width: 100%; /* Seems like a bug on having collapsed absolute content */

        @media (min-width: @breakpoint-tablet) {
            &::before {
                .font-size(200px);
            }
        }

        @media (min-width: @breakpoint-desktop-small) {
            &::before {
                .font-size(280px);
                font-size: 20vw;
                line-height: 500px;
            }
        }

        @media (min-width: @breakpoint-desktop-large) {
            &::before {
                .font-size(280px);
            }
        }

        &::before {
            .absolute-cover();
            .font-size(180px);
            color: @text-color-light;
            content: "404";
            display: block;
            line-height: 1;
            margin: auto;
            opacity: 0.3;
            padding: 20px 0;
            text-align: center;
        }
    }

    &-unicorn {
        .absolute-cover();
        animation: bounceinfinity 5s infinite;
        height: 240px; /* An explicit height needed for Android bug */
        margin: 0 auto;
        width: 100%;

        @media (min-width: @breakpoint-desktop-small) {
            height: 500px;
            position: relative;
        }

        /* Unicorn wing parts */
        &-leftWing {
            animation: flapWingLeft 2.5s infinite;
            transform-origin: center;
        }

        &-rightWing {
            animation: flapWingRight 2.5s infinite;
            transform-origin: center;
        }
    }

    &-content {
        .make-md-column(6);
        .make-md-column-pull(6);
        color: @text-color-light;
        padding-bottom: 40px;
        padding-top: 40px;
        text-align: center;

        @media (min-width: @breakpoint-desktop-small) {
            text-align: left;
        }
    }

    &-header {
        margin-bottom: 40px;

        &-title {
            .type(heading1);
            font-weight: bold;
            margin-bottom: 0;
            text-transform: uppercase;

            @media (min-width: @breakpoint-tablet) {
                .type(hero);
            }
        }

        &-byLine {
            .type(heading3);

            @media (min-width: @breakpoint-tablet) {
                .type(heading2);
            }
        }
    }

    &-suggestion {
        margin-bottom: 40px;

        &-title {
            .type(heading3);
            font-weight: bold;

            @media (min-width: @breakpoint-tablet) {
                .type(heading2);
            }
        }
    }

    &-links {
        .list-unstyled();
        .type(heading3);
        font-weight: bold;

        &-item {
            margin-bottom: 12px;
        }

        &-link {
            color: @primary-color-xxxlight;

            &:hover {
                color: @primary-color-xxxlight;
            }
        }
    }

    &-action {
        margin-bottom: @spacing-xxlarge;
        padding-top: @spacing-small;
    }

    &-alert {
        margin-bottom: @spacing-large;
        margin-top: @spacing-mid;

        &-heading {
            .type(heading3);
        }

        &-desc {
            margin-top: @spacing-xsmall;
        }
    }

    &-jobHeader {
        .type(heading1);
        margin-top: @spacing-large;
    }
}
