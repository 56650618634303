/**
 * Input Combo
 *
 * LESS Module: InputCombo.less
 *
 * .InputCombo--bordered     - Adds a default border
 * .InputCombo--compact      - Removes padding
 * .InputCombo--highlighted  - Adds a box shadow for highlighting
 *
 * Markup: input-combo.html
 *
 * Styleguide Forms - Form Elements - Input Combo
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import (reference) "inputs.less";

.InputCombo {
    @this: InputCombo;

    align-items: center;
    background: @septenary-color-xxxlight;
    border-radius: @border-radius;
    display: flex;
    padding: @spacing-xxsmall;

    &-inner {
        align-items: center;
        border-radius: @border-radius;
        display: flex;
        flex: 1 1 100%;
        width: 100%;

        &--divider {
            border-radius: 0;
            border-right: 1px solid @border-color-light;
        }

        &--secondary {
            flex: 0 0 auto;
            width: auto; /* Override width: 100% from input default value */
        }
    }

    &-icon {
        flex: 0 0 auto;
        margin-left: @spacing-xsmall;
    }

    /* Needed to override global input.
       TODO: This needs to be moved into its own module */
    .@{this}-input.@{this}-input {
        .type(landing);
        border: 0;
        border-radius: 0;
        flex: 1 1 auto;
        padding: 0 @spacing-xsmall;

        &:focus {
            box-shadow: none;
        }
    }

    &-title {
        .type(detail);
        color: @text-color-dark;
        display: none;
        font-weight: bold;
        letter-spacing: 1px;
        margin: @spacing-small auto;
        text-transform: uppercase;
    }

    &-btn {
        flex: 1 0 auto;
        white-space: nowrap;

        &--secondary {
            border-left: 1px solid @border-color-light;

            /* Overriding btn-link:hover */
            &:hover {
                border-left: 1px solid @border-color-light;
            }
        }
    }

    &--bordered {
        border: 1px solid @border-color-dark;
    }

    &--compact {
        padding: 0;
    }

    &--highlighted {
        .box-shadow-level-02();
    }

    &--stacked {
        /* We don't have container queries so this has to be made :( */
        flex-wrap: wrap;

        .@{this}-btn {
            margin-top: @spacing-xsmall;
            width: 100%;
        }

        .@{this}-inner {
            border: 1px solid @border-color-dark;
        }
    }

    @media (max-width: @breakpoint-tablet) {
        .is-mobile-first & {
            flex-wrap: wrap;
            position: relative;

            &-input {
                .type(landing);
            }

            &-btn {
                margin-top: @spacing-xsmall;
                width: 100%;
            }

            &-inner {
                border: 1px solid @border-color-dark;
                flex: 1 1 100%;

                &--divider {
                    margin-bottom: @spacing-xxsmall;
                }
            }

            &-title {
                display: block;
            }
        }
    }
}
