/**
 * Tags
 *
 * Styleguide: Tags
 */

/**
 * Promotion tags
 *
 * Used to display what promotions a project/contest/service/site has available
 *
 * Markup: promotion-tags.html
 *
 * Styleguide: Tags - Promotion Tags
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.promotion-tag {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 23px;
    padding: 0 15px;
    border-radius: 20px;
    list-style: none;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    .type(tiny);
    white-space: normal;

    &.has-no-icon {
        [class*="fl-icon-"] {
            display: none;
        }
    }

    [class*="fl-icon-"] {
        display: inline-block;
        width: 17px;
        height: 17px;
        margin: 3px 7px 0 -12px;
        border-radius: 50%;
        line-height: 17px;
        vertical-align: top;
        background: @text-color-light;
        .type(detail);
    }

    &.promotion-tag-small {
        height: 14px;
        padding: 3px 7px 2px;
        border-radius: 7px;
        font-size: 9px;
        line-height: 1;

        > [class*="fl-icon-"] {
            display: none;
        }

        &::after {
            display: none;
        }
    }

    &.promotion-featured {
        .promotion-tag-constructor(@featured-color);

        [class*="fl-icon-"] {
            line-height: 15px;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                line-height: 17px;
            }
        }
    }

    &.promotion-urgent {
        .promotion-tag-constructor(@hot-deal-color);

        [class*="fl-icon-"] {
            .type(heading3);
            color: @septenary-color-xxxlight;
            background-color: transparent;
        }
    }

    &.promotion-sealed {
        .promotion-tag-constructor(@sealed-color);

        [class*="fl-icon-"] {
            padding-left: 1px;
            font-size: 13px;
            line-height: 16px;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                padding-left: 2px;
                line-height: 17px;
            }
        }
    }

    &.promotion-fulltime {
        .promotion-tag-constructor(@fulltime-color);

        [class*="fl-icon-"] {
            line-height: 15px;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                padding-right: 1px;
                line-height: 17px;
            }
        }
    }

    &.promotion-assisted {
        .promotion-tag-constructor(@assisted-color);

        [class*="fl-icon-"] {
            font-size: 16px;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                line-height: 18px;
            }
        }
    }

    &.promotion-highlight {
        .promotion-tag-constructor(@highlight-color);

        [class*="fl-icon-"] {
            line-height: 15px;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                line-height: 17px;
            }
        }
    }

    &.promotion-private {
        .promotion-tag-constructor(@private-color);

        [class*="fl-icon-"] {
            font-size: 13px;
            line-height: 16px;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                line-height: 18px;
            }
        }
    }

    &.promotion-nda {
        .promotion-tag-constructor(@nda-color);

        [class*="fl-icon-"] {
            padding-left: 3px;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                line-height: 18px;
            }
        }
    }

    &.promotion-ip-contract {
        .promotion-tag-constructor(@ip-contract-color);

        [class*="fl-icon-"] {
            padding-left: 3px;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                line-height: 18px;
            }
        }
    }

    &.promotion-nca {
        .promotion-tag-constructor(@denary-color-dark);

        [class*="fl-icon-"] {
            padding-left: 3px;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                line-height: 18px;
            }
        }
    }

    &.promotion-extend {
        .promotion-tag-constructor(@extend-color);

        [class*="fl-icon-"] {
            padding-left: 3px;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                line-height: 18px;
            }
        }
    }

    &.promotion-local {
        .promotion-tag-constructor(@local-color);
    }

    &.promotion-status {
        .promotion-tag-constructor(@quaternary-color);
    }

    &.promotion-guaranteed {
        .promotion-tag-constructor(@guaranteed-color);

        [class*="fl-icon-"] {
            line-height: 15px;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                padding-right: 1px;
                line-height: 17px;
            }
        }
    }

    &.promotion-topContest {
        .promotion-tag-constructor(@topContest-color);

        [class*="fl-icon-"] {
            line-height: 15px;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                padding-right: 1px;
                line-height: 17px;
            }
        }
    }

    &.promotion-project-management {
        .promotion-tag-constructor(@management-color);

        [class*="fl-icon-"] {
            padding-left: @spacing-xxsmall;

            @media screen and (-webkit-min-device-pixel-ratio:0) {
                line-height: 18px;
            }
        }
    }
}

.promotion-tag-constructor(@color) {
    color: @septenary-color-xxxlight;
    background-color: @color;

    [class*="fl-icon-"] {
        color: @color;
    }
}
